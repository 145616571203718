import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./Home";
import Terms from "./terms-of-use";
import Privacy from "./privacy-policy";
import CreativeCommons from "./creative-commons";
import Unsubscribe from "./unsubscribe";

const App = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />}></Route>
      <Route exact path="/terms-of-use" element={<Terms />}></Route>
      <Route exact path="/privacy-policy" element={<Privacy />}></Route>
      <Route
        exact
        path="/creative-commons"
        element={<CreativeCommons />}
      ></Route>
      <Route exact path="/unsubscribe/:token" element={<Unsubscribe />}></Route>
    </Routes>
  );
};

export default App;
