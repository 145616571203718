import _get from "lodash/get";
import { Fetch } from "./Utils";

export function createContactRequest({
  contactRequest,
  onSuccess,
  setLoading,
  setError,
}) {
  request({
    path: "/contacts",
    method: "POST",
    body: contactRequest,
    onSuccess,
    setLoading,
    setError,
  });
}

export function createSubscriber({ profile, onSuccess, setLoading, setError }) {
  request({
    path: "/subscribers",
    method: "POST",
    body: profile,
    onSuccess,
    setLoading,
    setError,
  });
}

export function getSubscriber({ token, onSuccess, setLoading, setError }) {
  request({
    path: `/subscribers/${token}`,
    method: "GET",
    onSuccess,
    setLoading,
    setError,
  });
}

export function deleteSubscriber({ token, onSuccess, setLoading, setError }) {
  request({
    path: `/subscribers/${token}`,
    method: "DELETE",
    onSuccess,
    setLoading,
    setError,
  });
}

export function request({
  path,
  method,
  body,
  token,
  onSuccess,
  setLoading,
  setError,
}) {
  const options = {
    method,
    body: body && JSON.stringify(body),
    headers: token ? { Authorization: `Bearer ${token}` } : {},
  };

  setLoading && setLoading(true);

  Fetch(path, options)
    .then((response) => {
      if (response) {
        setError && setError();
        setLoading && setLoading(false);
        onSuccess && onSuccess(response);
      }
    })
    .catch((error) => {
      console.error("error", error);
      setError && setError(_get(error, "message", "Something went wrong"));
      setLoading && setLoading(false);
    });
}
