import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import * as Api from "./api";

import logo from "./images/remedy-zones-logo.svg";
import unsubscribeImg from "./images/health.jpg";

import "./styles/styles.css";

function Unsubscribe() {
  let { token } = useParams();

  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingUnsubscribe, setLoadingUnsubscribe] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (token) {
      Api.getSubscriber({ token, onSuccess: setProfile, setLoading, setError });
    }
  }, [token]);

  /**
	 * Profile model example
	 *
	{
			"id": 1,
			"name": "John Doe",
			"email": "johndoe@example.com",
			"uuid": "8cd693f5-0fee-400c-b85f-2a9690974d4c",
			"lists": [
					{
							"status": "subscribed",
							"id": 1,
							"uuid": "149451e1-63dc-473a-b1bd-0916328fe19e",
							"name": "Test List",
							"type": "regular"
					}
			]
	}
	*/

  const handleUnsubscribe = useCallback(
    (event) => {
      console.log("unsubscribe", token);
      event.preventDefault();
      if (token) {
        Api.deleteSubscriber({
          token,
          onSuccess: () => {
            setSuccess(true);
          },
          setLoading: setLoadingUnsubscribe,
          setError,
        });
      }
    },
    [token]
  );

  return (
    <div className="simple color">
      <header className="header">
        <div className="container">
          <a href="/" className="logo">
            <img src={logo} alt="Remedy Zones" />
          </a>
        </div>
      </header>
      <main>
        <section className="unsubscribe">
          {success && (
            <div className="success">
              <p>You have been unsubscribed.</p>
            </div>
          )}
          {!success && (
            <div className="container">
              <div className="unsubscribe-form">
                <h2>Communication Preferences</h2>
                <p>
                  <b>{profile && profile.email}</b>
                </p>
                <p>
                  If this is not your email address, please ignore this page
                  since the email associated with this page was most likely
                  forwarded to you.
                </p>

                <button
                  type="submit"
                  className="button"
                  onClick={handleUnsubscribe}
                  disabled={loading || loadingUnsubscribe}
                >
                  No more calls, no more texts - we're breaking up
                </button>
                {loadingUnsubscribe && (
                  <div className="loading">
                    <p>Unsubscribing...</p>
                  </div>
                )}
                {error && (
                  <div className="error-message">
                      There was an error unsubscribing you. Please try again
                      later.
                  </div>
                )}
              </div>
              <div className="unsubscribe-image">
                <img
                  src={unsubscribeImg}
                  className="unsubscribe-img"
                  alt="Nutrition"
                />
              </div>
            </div>
          )}
        </section>
      </main>
      <footer className="footer">
        <div className="container">
          <nav className="footer-nav">
            <a href="/terms-of-use">Terms of Use</a>
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/creative-commons">Creative Commons / DMCA</a>
          </nav>

          <p className="copyright">
            Copyright © 2023 Remedy Zones. All Rights Reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Unsubscribe;
