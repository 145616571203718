import logo from "./images/remedy-zones-logo.svg";

import "./styles/styles.css";

function CreativeCommons() {
  return (
    <div className="simple">
      <header className="header">
        <div className="container">
          <a href="/" className="logo">
            <img src={logo} alt="Remedy Zones" />
          </a>
        </div>
      </header>
      <main>
        <section className="banner">
          <div className="container">
            <h1>Creative Commons / DMCA</h1>
            <p className="note">
              Last Updated: June 1, 2023
            </p>
          </div>
        </section>
        <section className="content">
          <div className="container">
            <p>
              The Remedyzones.com (“RZ”) website uses and republishes articles
              and information created by RZ, it’s editors, other credentialed
              contributors, and materials already published or in the public
              domain.
            </p>
            <p>
              We may use these materials either by acquisition of the rights,
              permission to use the materials, or under the Creative Commons
              Attribution policies 4.0.
            </p>
            <p>
              We always attempt to give proper TASL credit to all sources,
              authors, publications and publishers.
            </p>
            <p>
              If you feel we have used material owned by you, you may contact us
              at any time at <a href="mailto:support@remedyzones.com">support@remedyzones.com</a> or you may send us a DMCA
              (Digital Millennium Copyright Act) notice as follows and we will
              take immediate action to correct any unintended or unauthorized
              use:
            </p>
            <h3>Notice and Takedown Procedures; and Copyright Agent</h3>
            <p>
              If you believe any materials accessible on or from the Site
              infringe your copyright, you may request removal of those
              materials (or access thereto) from this web site by contacting
              RZ’s copyright agent (identified below) and providing the
              following information:
            </p>
            <p>
              Identification of the copyrighted work that you believe to be
              infringed. Please describe the work, and where possible include a
              copy or the location (e.g., URL) of an authorized version of the
              work.
            </p>
            <p>
              Identification of the material that you believe to be infringing
              and its location. Please describe the material and provide us with
              its URL or any other pertinent information that will allow us to
              locate the material.
            </p>
            <p>
              Your name, address, telephone number and (if available) e-mail
              address.
            </p>
            <p>
              A statement that you have a good faith belief that the complained
              of use of the materials is not authorized by the copyright owner,
              its agent, or the law.
            </p>
            <p>
              A statement that the information that you have supplied is
              accurate, and indicating that “under penalty of perjury,” you are
              the copyright owner or are authorized to act on the copyright
              owner’s behalf.
            </p>
            <p>
              A signature or the electronic equivalent from the copyright holder
              or authorized representative.
            </p>
            <p>
              RZ’s agent for copyright issues relating to this web site is as
              follows:
            </p>
            <p>
              Remedy Zone
              <br />
              Attn: Office of Privacy
              <br />
              1942 Broadway St. STE 314C
              <br />
              Boulder, CO 80302
            </p>
          </div>
        </section>
      </main>
      <footer className="footer">
        <div className="container">
          <nav className="footer-nav">
            <a href="/terms-of-use">Terms of Use</a>
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/creative-commons">Creative Commons / DMCA</a>
          </nav>

          <p className="copyright">
            Copyright © 2023 Remedy Zones. All Rights Reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default CreativeCommons;
