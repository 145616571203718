import logo from "./images/remedy-zones-logo.svg";

import "./styles/styles.css";

function TermsOfUse() {
  return (
    <div className="simple">
      <header className="header">
        <div className="container">
          <a href="/" className="logo">
            <img src={logo} alt="Remedy Zones" />
          </a>
        </div>
      </header>
      <main>
        <section className="banner">
          <div className="container">
            <h1>Terms and Conditions of Use</h1>
            <p className="note">
              Last Updated: June 1, 2023
            </p>
          </div>
        </section>
        <section className="content">
          <div className="container">
            <p>
              <b>
                PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE
                USING THIS SITE.
              </b>
            </p>
            <p>
              By using this site, you signify your consent to these Terms and
              Conditions. If you do not agree to all of these Terms and
              Conditions, do not use this site!
            </p>
            <p>
              Remedy Zones (“RZ”) may revise and update these Terms and
              Conditions at any time. Your continued usage of the RZ website
              (“RZ Site,” or the “Site,”) will mean you accept those changes.
            </p>
            <h3 className="center">The Site Does Not Provide Medical Advice</h3>
            <p>
              The contents of the RZ Site, such as text, graphics, images, and
              other materials created by RZ or obtained from RZ’s licensors, and
              other materials contained on the RZ Site (collectively, “Content”)
              are for informational purposes only. The Content is not intended
              to be a substitute for professional medical advice, diagnosis, or
              treatment. Always seek the advice of your physician or other
              qualified health provider with any questions you may have
              regarding a medical condition. Never disregard professional
              medical advice or delay in seeking it because of something you
              have read on the RZ Site!
            </p>
            <h3>
              If you think you may have a medical emergency, call your doctor or
              911 immediately.
            </h3>
            <p>
              RZ does not recommend or endorse any specific tests, physicians,
              products, procedures, opinions, or other information that may be
              mentioned on the Site. Reliance on any information provided by RZ,
              RZ employees, others appearing on the Site at the invitation of
              RZ, or other visitors to the Site is solely at your own risk.
            </p>
            <h3>Children’s Privacy</h3>
            <p>
              We are committed to protecting the privacy of children. You should
              be aware that this Site is not intended or designed to attract
              children under the age of 13. We do not collect personally
              identifiable information from any person we actually know is a
              child under the age of 13.
            </p>
            <h3>Use of the Content</h3>
            <p>
              The Content posted on this Site is protected by the copyright laws
              in the United States and in foreign countries. RZ authorizes you
              to view or download a single copy of the Content solely for your
              personal, noncommercial use if you include the copyright notice
              located at the end of the material, for example: “©2023, RZ. All
              rights reserved” or reference the appropriate citation for any
              materials published other than by RZ, and other copyright and
              proprietary rights notices that are contained in the Content. Any
              special rules for the use of certain software and other items
              accessible on the RZ Site may be included elsewhere within the
              Site and are incorporated into these Terms and Conditions by
              reference.
            </p>
            <p>
              Title to the Content remains with RZ or its licensors or the
              original publisher or owner of such content. Any use of the
              Content not expressly permitted by these Terms and Conditions is a
              breach of these Terms and Conditions and may violate copyright,
              trademark, and other laws. Content and other features are subject
              to change or termination without notice in the editorial
              discretion of RZ. All rights not expressly granted herein are
              reserved to RZ and its licensors. Certain Content on this Site is
              used under the Creative Commons License, or other license or
              rights permitting the republication of articles or materials for
              educational purposes.
            </p>
            <p>
              If you violate any of these Terms and Conditions, your permission
              to use the Content automatically terminates and you must
              immediately destroy any copies you have made of any portion of the
              Content.
            </p>
            <h3>Liability of RZ and Its Licensors</h3>
            <p>The use of the RZ Site and the Content is at your own risk.</p>
            <p>
              When using the RZ Site, information will be transmitted over a
              medium that may be beyond the control and jurisdiction of RZ and
              its suppliers. Accordingly, RZ assumes no liability for or
              relating to the delay, failure, interruption, or corruption of any
              data or other information transmitted in connection with use of
              the RZ Site.
            </p>
            <p>
              The RZ Site and the Content are provided on an “as is” basis. RZ,
              ITS LICENSORS, AND ITS SUPPLIERS, TO THE FULLEST EXTENT PERMITTED
              BY LAW, DISCLAIM ALL WARRANTIES, EITHER EXPRESS OR IMPLIED,
              STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO THE IMPLIED
              WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES’
              RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE. Without limiting the
              foregoing, RZ, its licensors, and its suppliers make no
              representations or warranties about the accuracy, reliability,
              completeness, currentness, or timeliness of the Content, software,
              links, or communications provided on or through the use of the RZ
              Site or RZ; the satisfaction of any government regulations
              requiring disclosure of information on prescription drug products
              or the approval or compliance of any software tools with regard to
              the Content contained on the RZ Site.
            </p>
            <p>
              In no event shall RZ, its licensors, its suppliers, or any third
              parties mentioned on the RZ Site be liable for any damages
              (including, without limitation, incidental and consequential
              damages, personal injury/wrongful death, lost profits, or damages
              resulting from lost data or business interruption) resulting from
              the use of or inability to use the RZ Site or the Content, whether
              based on warranty, contract, tort, or any other legal theory, and
              whether or not RZ, its licensors, its suppliers, or any third
              parties mentioned on the RZ Site are advised of the possibility of
              such damages. RZ, its licensors, its suppliers, or any third
              parties mentioned on the RZ Site are not liable for any personal
              injury, including death, caused by your use or misuse of the Site,
              Content, or Public Areas (as defined below). Any claims arising in
              connection with your use of the Site, any Content, or the Public
              Areas must be brought within one (1) year of the date of the event
              giving rise to such action occurred. Remedies under these Terms
              and Conditions are exclusive and are limited to those expressly
              provided for in these Terms and Conditions.
            </p>
            <h3>Advertisements, Searches, and Links to Other Sites</h3>
            <p>
              RZ may provide links to third-party web sites. RZ also may select
              certain sites as priority responses to search terms you enter and
              RZ may agree to allow advertisers to respond to certain search
              terms with advertisements or sponsored content. RZ does not
              recommend and does not endorse the content on any third-party
              websites. RZ is not responsible for the content of linked
              third-party sites, sites framed within the RZ Site, third-party
              sites provided as search results, or third-party advertisements,
              and does not make any representations regarding their content or
              accuracy. Your use of third-party websites is at your own risk and
              subject to the terms and conditions of use for such sites. RZ does
              not endorse any product, service, or treatment advertised on the
              RZ Site. For more information, read our Advertising Policy and our
              Sponsor Policy.
            </p>
            <h3>Indemnity</h3>
            <p>
              You agree to defend, indemnify, and hold RZ, its officers,
              directors, employees, agents, licensors, and suppliers, harmless
              from and against any claims, actions or demands, liabilities and
              settlements including without limitation, reasonable legal and
              accounting fees, resulting from, or alleged to result from: (a)
              your use of the Site, (b) any User Content you post or upload, (c)
              your use of or reliance on any User Content, or (d) your violation
              of these Terms and Conditions.
            </p>
            <h3>General</h3>
            <p>
              RZ is based in the United States of America with principal offices
              in Boulder, CO. RZ makes no claims that RZ, or any Site within the
              RZ Network and the Content are appropriate or may be downloaded
              outside of the United States. Access to the Content may not be
              legal by certain persons or in certain countries. If you access RZ
              from outside the United States, you do so at your own risk and are
              responsible for compliance with the laws of your jurisdiction.
            </p>
            <h3>Jurisdiction</h3>
            <p>
              You expressly agree that exclusive jurisdiction for any dispute
              with RZ, or in any way relating to your use of the RZ Site,
              resides in the courts of the State of Colorado and you further
              agree and expressly consent to the exercise of personal
              jurisdiction in the courts of the State of Colorado in connection
              with any such dispute including any claim involving RZ or its
              affiliates, subsidiaries, employees, contractors, officers,
              directors, telecommunication providers, and content providers.
            </p>
            <p>
              These Terms and Conditions are governed by the internal
              substantive laws of the State of Colorado, without respect to its
              conflict of laws principles. If any provision of these Terms and
              Conditions is found to be invalid by any court having competent
              jurisdiction, the invalidity of such provision shall not affect
              the validity of the remaining provisions of these Terms and
              Conditions, which shall remain in full force and effect. No waiver
              of any of these Terms and Conditions shall be deemed a further or
              continuing waiver of such term or condition or any other term or
              condition.
            </p>
            <h3>Notice and Takedown Procedures; and Copyright Agent</h3>
            <p>
              If you believe any materials accessible on or from the Site
              infringe your copyright, you may request removal of those
              materials (or access thereto) from this web site by contacting
              RZ’s copyright agent (identified below) and providing the
              following information:
            </p>
            <p>
              Identification of the copyrighted work that you believe to be
              infringed. Please describe the work, and where possible include a
              copy or the location (e.g., URL) of an authorized version of the
              work.
            </p>
            <p>
              Identification of the material that you believe to be infringing
              and its location. Please describe the material, and provide us
              with its URL or any other pertinent information that will allow us
              to locate the material.
            </p>
            <p>
              Your name, address, telephone number and (if available) e-mail
              address.
            </p>
            <p>
              A statement that you have a good faith belief that the complained
              of use of the materials is not authorized by the copyright owner,
              its agent, or the law.
            </p>
            <p>
              A statement that the information that you have supplied is
              accurate, and indicating that “under penalty of perjury,” you are
              the copyright owner or are authorized to act on the copyright
              owner’s behalf.
            </p>
            <p>
              A signature or the electronic equivalent from the copyright holder
              or authorized representative.
            </p>
            <p>
              RZ’s agent for copyright issues relating to this web site is as
              follows:
            </p>
            <p>
              Remedy Zone
              <br />
              Attn: Office of Privacy
              <br />
              1942 Broadway St. STE 314C
              <br />
              Boulder, CO 80302
            </p>
            <p>
              In an effort to protect the rights of copyright owners, RZ
              maintains a policy for the termination, in appropriate
              circumstances, of subscribers and account holders of the Site who
              are repeat infringers.
            </p>
            <h3>Complete Agreement</h3>
            <p>
              Except as expressly provided in a particular “legal notice” on the
              RZ Site, these Terms and Conditions and the RZ Privacy Policy
              constitute the entire agreement between you and RZ with respect to
              the use of the RZ Site, and Content.
            </p>
            <p>
              Thank you for your cooperation. We hope you find the RZ.com Site
              helpful and convenient to use!
            </p>
          </div>
        </section>
      </main>
      <footer className="footer">
        <div className="container">
          <nav className="footer-nav">
            <a href="/terms-of-use">Terms of Use</a>
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/creative-commons">Creative Commons / DMCA</a>
          </nav>

          <p className="copyright">
            Copyright © 2023 Remedy Zones. All Rights Reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default TermsOfUse;
