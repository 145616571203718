import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";

import * as Api from "./api";

import logo from "./images/remedy-zones-logo.svg";

import aboutImg from "./images/lifestyle.jpg";
import bannerImg from "./images/fitness.jpg";
import contactImg from "./images/nutrition.jpg";
import "./styles/styles.css";

function Home() {
  const {
    register: registerContact,
    handleSubmit: handleSubmitContact,
    control: controlContact,
    reset: resetContact,
    formState: { errors: errorsContact },
  } = useForm({ shouldUseNativeValidation: false });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ shouldUseNativeValidation: false });

  const [contactLoading, setContactLoading] = useState(false);
  const [contactError, setContactError] = useState(null);
  const [contactSuccess, setContactSuccess] = useState(null);

  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [subscribeError, setSubscribeError] = useState(null);
  const [subscribeSuccess, setSubscribeSuccess] = useState(null);

  useEffect(() => {
    if (!contactSuccess) {
      return;
    }
    resetContact();
  }, [contactSuccess, resetContact]);

  useEffect(() => {
    if (!subscribeSuccess) {
      return;
    }
    reset();
  }, [subscribeSuccess, reset]);

  const onContactSuccess = () => {
    setContactSuccess(true);
    setContactLoading(false);
  };

  const onContactLoading = () => {
    setContactLoading(true);
    setContactError(null);
  };

  const onContactError = (error) => {
    setContactError(error);
    setContactLoading(false);
  };

  const onContactSubmit = async (data) => {
    Api.createContactRequest({
      contactRequest: data,
      onSuccess: onContactSuccess,
      setLoading: onContactLoading,
      setError: onContactError,
    });
  };

  const onSubscribeSuccess = () => {
    setSubscribeSuccess(true);
    setSubscribeLoading(false);
  };

  const onSubscribeLoading = () => {
    setSubscribeLoading(true);
    setSubscribeError(null);
  };

  const onSubscribeError = (error) => {
    setSubscribeError(error);
    setSubscribeLoading(false);
  };

  const onSubscribeSubmit = async (data) => {
    Api.createSubscriber({
      profile: data,
      onSuccess: onSubscribeSuccess,
      setLoading: onSubscribeLoading,
      setError: onSubscribeError,
    });
  };

  const handleFocus = (e) => {
    e.target.parentElement.classList.add("active");
  };

  const handleBlur = (e) => {
    if (!e.target.value) {
      e.target.parentElement.classList.remove("active");
    }
  };

  console.log("response:");
  console.log(subscribeSuccess);
  console.log(subscribeError);
  console.log(subscribeLoading);

  return (
    <div className="home">
      <header className="header">
        <div className="container">
          <a href="/" className="logo">
            <img src={logo} alt="Remedy Zones" />
          </a>
        </div>
      </header>
      <main>
        <section className="banner">
          <div className="container">
            <div className="banner-text">
              <h1>Get Our Free Report By Email</h1>
              <p>
                <b>
                  Get our free Healthy Lifestyle Newsletters sent by email.
                  Packed with the latest tips for health, nutrition, fitness and
                  more.
                </b>
              </p>
              <form
                onSubmit={handleSubmit(onSubscribeSubmit)}
                className="banner-form"
              >
                <div className="banner-form-wrapper">
                  <fieldset className="fieldset">
                    <div className="text-field">
                      <label>
                        First name<b>*</b>
                      </label>
                      <input
                        {...register("firstName", { required: true })}
                        disabled={subscribeLoading}
                        className="required"
                        type="text"
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                      />
                      {errors.firstName && (
                        <span className="invalid-feedback">
                          This field is required
                        </span>
                      )}
                    </div>
                    <div className="text-field">
                      <label>
                        Last name<b>*</b>
                      </label>
                      <input
                        {...register("lastName", { required: true })}
                        disabled={subscribeLoading}
                        className="required"
                        type="text"
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                      />
                      {errors.lastName && (
                        <span className="invalid-feedback">
                          This field is required
                        </span>
                      )}
                    </div>
                  </fieldset>
                  <div className="text-field">
                    <label>
                      Email<b>*</b>
                    </label>
                    <input
                      {...register("email", {
                        required: true,
                        validate: {
                          maxLength: (v) =>
                            v.length <= 50 ||
                            "The email should have at most 50 characters",
                          matchPattern: (v) =>
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                              v
                            ) || "Email address must be a valid address",
                        },
                      })}
                      disabled={subscribeLoading}
                      className="required"
                      type="email"
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    {errors.email?.message && (
                      <small>{errors.email.message}</small>
                    )}
                  </div>
                </div>
                <p>
                  <b>
                    Get our free Healthy Lifestyle Newsletters sent by email.
                    Packed with the latest tips for health, nutrition, fitness
                    and more. No purchase or no credit card required, you can
                    cancel any time. Educational information sent straight to
                    your inbox.
                  </b>
                </p>
                <p>
                  <b>Our email newsletter is a free service of Remedy Zones.</b>
                </p>
                <button
                  type="submit"
                  className="button"
                  disabled={subscribeLoading}
                >
                  Subscribe now!
                </button>
                {subscribeSuccess && (
                  <div className="success-message">
                    You have been successfully subscribed!
                  </div>
                )}

                {subscribeError && (
                  <div className="error-message">
                    Something went wrong. Please try again later.
                  </div>
                )}
              </form>
            </div>
            <div className="banner-image">
              <img src={bannerImg} className="banner-img" alt="Fitness" />
            </div>
          </div>
        </section>

        <section className="about">
          <div className="container">
            <div className="about-image">
              <img src={aboutImg} className="about-img" alt="Lifestyle" />
            </div>
            <div className="about-text">
              <h2>Why should you subscribe?</h2>
              <p>
                Remedy Zones is a website and email newsletter service dedicated
                to providing the information on health, fitness, and lifestyle
                with an emphasis on natural solutions. We seek to provide the
                most scientifically accurate information, clinical studies, and
                tips from doctors and other highly credentialed medical
                professionals about maintaining a healthy lifestyle for men and
                women.
              </p>

              <p>
                Remedy Zones receives financial support through advertisers and
                other sponsorships. It is this funding that allows us to develop
                the content on this website and the newsletters we email to our
                subscribers.
              </p>
            </div>
          </div>
        </section>

        <section className="contact">
          <div className="title">
            <h2>Contact Us</h2>
            <p>
              <b>
                Have any questions? Fill out the form below and we’ll get back
                to you as soon as possible.
              </b>
            </p>
          </div>
          <div className="container">
            <form
              onSubmit={handleSubmitContact(onContactSubmit)}
              className="contact-form"
            >
              <div className="text-field">
                <label>
                  Name<b>*</b>
                </label>
                <input
                  {...registerContact("name", { required: true })}
                  className="required"
                  type="text"
                  disabled={contactLoading}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                {errorsContact.name && (
                  <span className="invalid-feedback">
                    This field is required
                  </span>
                )}
              </div>
              <div className="text-field">
                <label>Company Name</label>
                <input
                  {...registerContact("companyName", { required: true })}
                  type="text"
                  disabled={contactLoading}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                {errorsContact.companyName && (
                  <span className="invalid-feedback">
                    This field is required
                  </span>
                )}
              </div>
              <div className="text-field">
                <label>
                  Email<b>*</b>
                </label>
                <input
                  {...registerContact("email", {
                    required: true,
                    validate: {
                      maxLength: (v) =>
                        v.length <= 50 ||
                        "The email should have at most 50 characters",
                      matchPattern: (v) =>
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                        "Email address must be a valid address",
                    },
                  })}
                  disabled={contactLoading}
                  className="required"
                  type="email"
                  required
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                {errorsContact.email?.message && (
                  <small>{errors.email.message}</small>
                )}
              </div>
              <div className="text-field">
                <label>Phone</label>
                <Controller
                  name="phone"
                  control={controlContact}
                  rules={{
                    validate: (value) => isValidPhoneNumber(value),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      disabled={contactLoading}
                      onChange={onChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      value={value}
                      defaultCountry="US"
                      id="phone"
                    />
                  )}
                />
                {errorsContact["phone"] && (
                  <p className="error-message">Invalid Phone</p>
                )}
              </div>
              <div className="text-field">
                <label>
                  Message<b>*</b>
                </label>
                <textarea
                  {...registerContact("message", { required: true })}
                  disabled={contactLoading}
                  className="required"
                  required
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                {errorsContact.message && (
                  <span className="invalid-feedback">
                    This field is required
                  </span>
                )}
              </div>

              <button
                type="submit"
                className="button"
                disabled={contactLoading}
              >
                Submit
              </button>

              {contactSuccess && (
                <div className="success-message">
                  Your message has been sent successfully!
                </div>
              )}

              {contactError && (
                <div className="error-message">
                  Something went wrong. Please try again later.
                </div>
              )}
            </form>
            <div className="contact-image">
              <img src={contactImg} className="contact-img" alt="Nutrition" />
            </div>
          </div>
        </section>
      </main>
      <footer className="footer">
        <div className="container">
          <nav className="footer-nav">
            <a href="/terms-of-use">Terms of Use</a>
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/creative-commons">Creative Commons / DMCA</a>
          </nav>

          <p className="copyright">
            Copyright © 2023 Remedy Zones. All Rights Reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Home;
