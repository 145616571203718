import logo from "./images/remedy-zones-logo.svg";

import "./styles/styles.css";

function PrivacyPolicy() {
  return (
    <div className="simple">
      <header className="header">
        <div className="container">
          <a href="/" className="logo">
            <img src={logo} alt="Remedy Zones" />
          </a>
        </div>
      </header>
      <main>
        <section className="banner">
          <div className="container">
            <h1>RZ Privacy Policy</h1>
            <p className="note">
              Last Updated: June 1, 2023
            </p>
          </div>
        </section>
        <section className="content">
          <div className="container">
            <p>
              RZ understands how important the privacy of personal information
              is to our users. This Privacy Policy will tell you what
              information we collect about you and about your use of RZ and its
              services. It will explain the choices you have about how your
              personal information is used and how we protect that information.
              We urge you to read this Privacy Policy carefully.
            </p>
            <p>
              This Privacy Policy applies to websites owned and operated by RZ
              that are intended for use by consumers (non-professionals) for
              non-commercial personal, family or household purposes, including
              remedyzones.com, and others as may be created, and including the
              mobile optimized versions of these sites and our Mobile Device
              Applications or “Apps” as may be created (we refer to these sites
              and Apps collectively as the “RZ Sites”). We refer to the RZ Sites
              and Apps, along with the information and services made available
              to you through the RZ Sites and Apps, as the “Services.”
            </p>
            <p>
              Except where noted, statements in this Privacy Policy with respect
              to the RZ Sites also apply to the Apps. If you do not want us to
              collect, use or disclose information about you and your use of the
              Services as described in this Privacy Policy, then you should not
              use the Services. By using the Services, you must agree to the RZ
              Terms of Use, which is the contract between us and users of the
              Services. By accepting the RZ Terms of Use, you confirm that you
              have read and understand this Privacy Policy and the RZ Cookie
              Policy and you acknowledge that we will store, use and otherwise
              process your information in the United States where we are
              located.
            </p>
            <p>
              References to “RZ,” “we” or “us” mean RZ , including any company
              that RZ controls (for example, a subsidiary that RZ owns). RZ may
              share information among its subsidiaries or websites that it owns
              or controls, but information collected under this Privacy Policy
              is always protected under the terms of this Privacy Policy. Except
              as otherwise noted in this Privacy Policy, RZ is the data
              controller responsible for the processing of your personal
              information as described in this Privacy Policy.
            </p>
            <h3>Information Collected About You</h3>
            <p>
              Some of our Services (such as certain quizzes, for example, or
              Services which require a registration) do not retain your personal
              information, while others store your personal information in
              accordance with this Privacy Policy. Even if you do not register
              with or provide any personal information to RZ, we may collect
              information about your use of the RZ Sites and Apps and the
              Services. We may also acquire information about our users from
              external sources.
            </p>
            <h3>
              When you use the Services, we collect information as follows:
            </h3>
            <h3>Registration</h3>
            <p>
              While you may use most of the Services without registering,
              certain Services do require that you register with RZ for them to
              function properly. If you choose to register or update an existing
              account with RZ or access certain Services, you may be required to
              provide certain personal information, such as your name, address,
              telephone number, gender, email address and date of birth, and a
              username and password to access your RZ account. You are
              responsible for ensuring the accuracy of the personal information
              that you submit to RZ.
            </p>
            <h3>Email Newsletters</h3>
            <p>
              At registration and at various times as you use the RZ Sites, you
              will be given the option of providing us with personal information
              in order to receive informational/promotional newsletters – such
              as a newsletter relating to a specific health condition – via
              email from RZ and/or directly from third parties. From time to
              time RZ may offer users of third party websites the opportunity to
              subscribe to our newsletters through those websites. If you elect
              to subscribe to one of our newsletters on a third party website,
              the operator of that website will provide us with the personal
              information you have provided in connection with the subscription
              request, which we may use in accordance with this Privacy Policy
              as though you had provided it directly to RZ.
            </p>
            <h3>Emails You Send to RZ</h3>
            <p>
              This Privacy Policy does not apply to information, content,
              business information, ideas, concepts or inventions that you send
              to RZ by email. If you want to keep content or business
              information, ideas, concepts or inventions private or proprietary,
              do not send them in an email to RZ.
            </p>
            <h3>Services and Device Information</h3>
            <p>
              When you access and use the Services, RZ automatically collects
              and stores in its server logs information from your browser or
              mobile device such as your IP address or unique device identifier,
              browser information (including referring URL), your preferences
              and settings, cookies and information about the content you have
              viewed and actions taken (e.g., search queries, ad engagement,
              clicks and the associated dates and times). RZ may also collect
              device-specific information when you install and use an App
              including your device model, operating system information,
              advertising ID (which is a unique, user-resettable identification
              number for advertising associated with a mobile device) and App
              version and usage information. When enabled by you, we collect
              precise location information provided by your mobile device, which
              you may disable through the device settings.
            </p>
            <h3>Precise Geolocation Data</h3>
            <p>
              When enabled by you, we may collect precise location information
              provided by your mobile device, such as latitude and longitude. We
              do so to provide you more relevant content including advertising
              based on your location. You may disable the collection and use of
              your location data through your device settings. To change
              location settings on your device, please refer to your device’s
              official knowledge base.
            </p>
            <h3>Cookies and Other Tracking Technologies</h3>
            <p>
              We and our partners use cookies to collect information about your
              use of the Services. “Cookies” are small data files assigned to
              your browser when you visit a RZ Site which enable recognition of
              your browser and collect and store information about your use of
              the Services, as described above. In addition to cookies, we and
              our partners use other tracking technologies that collect
              information about your use of the Services, including mobile
              identifiers and “web beacons” which are small graphic files
              (sometimes called “clear GIFs” or “web pixels”) embedded in a web
              page or email typically used to monitor activity and send relevant
              information back to a home server (which can belong to the host
              site, a network advertiser or some other third party). The
              information collected by such tracking technologies may be
              combined with other information that our partners have access to,
              including your name, email address and physical address, so that
              we can send you materials, either electronically or by direct
              mail, that may be of interest to you.
            </p>
            <p>
              Our advertising service partners may use cookies and other
              tracking technologies to collect information about your use of the
              RZ Sites, including content you have viewed. These third parties
              may use this information to help RZ deliver advertising on the RZ
              Sites and on other third party websites based on your browsing
              activity on the RZ Sites. RZ may further tailor the advertising on
              the RZ Sites and these other third party websites based on
              additional information to the extent known by RZ or these third
              parties.
            </p>
            <p>
              We may also work with third party ad networks to display
              advertising on our RZ Sites and on third party websites. Our ad
              network vendors use technologies to collect information about your
              activities on the RZ Sites and in our flagship RZ App to provide
              you cookie-based targeted advertising on our RZ Sites and on third
              party websites based upon your browsing activity and your
              interests.
            </p>
            <h3>Cross-Device Tracking</h3>
            <p>
              We and our service providers may collect and store information
              about users’ interactions with unaffiliated websites and
              applications that use our technologies, including cookies and
              similar tracking technologies. This allows us to infer the
              presence of a common user or household behind multiple devices. We
              do so in order to:
            </p>
            <ul>
              <li>detect and prevent fraud; </li>
              <li>
                allow users to use our service on one device and pick up
                seamlessly where they left off on another device;
              </li>
              <li>
                limit the number of times a user is shown the same
                advertisement, across known or inferred devices;
              </li>
              <li>
                provide personalized advertising on each device that is inferred
                from the browsing patterns on all of the devices
              </li>
            </ul>

            <h3>How Information Collected About You Is Used</h3>
            <p>
              Information about your use of the Services may be used for the
              following purposes:
            </p>
            <ul>
              <li>to provide, improve and create new Services, </li>
              <li>
                to respond to your inquiries and to send you administrative
                communications about the RZ Sites and Services,
              </li>
              <li>to obtain your feedback about the RZSites and Services, </li>
              <li>
                to send you secure electronic messages and personalized emails
                pertaining to your interests as inferred from your use of the
                Services, including news, announcements, reminders and
                opportunities from RZ,
              </li>
              <li>
                to statistically analyze trends and user behavior and activity
                including how frequently areas of the RZ Sites are visited, how
                the Services are being used and how many emails are received and
                opened,
              </li>
              <li>
                to provide you and people with similar demographic
                characteristics and interests with more relevant content
                including advertising both on and off the RZ Sites and Apps,
              </li>
              <li>to offer lead generation services, </li>
              <li>
                to detect and defend against fraud and other threats to the
                Services and our users,
              </li>
              <li>to identify issues with the Services, </li>
              <li>
                to conduct research and measurement activities, including those
                described below,
              </li>
              <li>to administer your account, and </li>
              <li>
                RZ may use personal information about you for other purposes
                that are disclosed to you at the time we collect the information
                and/or with your consent.
              </li>
            </ul>

            <p>
              RZ may combine your personal information and other information
              collected about your use of the Services, and also supplement with
              information from external sources for the purposes described in
              this Privacy Policy. For example, information that RZ collects
              about you may be combined by RZ with other information available
              to RZ through third parties for research and measurement purposes,
              including measuring the effectiveness of content, advertising or
              programs. This information from other sources may include age,
              gender, demographic, geographic, personal interests, product
              purchase activity or other information. We may report aggregate
              information, which is not able to be identified back to an
              individual user of the RZ Sites, to our current or prospective
              advertisers and other business partners.
            </p>
            <h3>Sharing Your Information</h3>
            <h3>Companies and Contractors that Work with RZ</h3>
            <p>
              RZ works with third party companies and contractors that help us
              provide the Services and to otherwise assist in the operation of
              the RZ Sites and Apps, including those that provide services
              relating to technology, data analysis, research, email management
              and deployment, sweepstakes and contest administration,
              advertising and marketing and/or content. If you are in the United
              States, we may also partner with certain service providers to
              better understand your experience on the RZ Sites, including
              technology that enables us to review and optimize the Services
              based on your interactions with certain pages (e.g., how much time
              you spend on which pages, which links you choose to click, what
              you do and don’t like, etc.) in an aggregated format. RZ
              contractors sometimes have limited access to your information in
              the course of providing products or services to RZ. We
              contractually require that our contractors not use or disclose
              your information for any purpose other than providing the limited
              service or function for RZ.
            </p>
            <h3>Third Party Advertisers and Third Party Websites</h3>
            <p>
              We may share information that we have about you, such as a cookie
              ID or IP address, with third party advertising service providers
              who may use this information, on our behalf, to help RZ deliver
              advertising on the RZ Sites as well as on third party websites, as
              further described in our Cookie Policy.
            </p>
            <p>
              Certain content, services and advertisements offered to you
              through the RZ Sites are served on, or contain links to, websites
              hosted and operated by a company other than RZ (“Third Party
              Websites”). RZ does not share your personal information with these
              Third Party Websites without your consent, but you should be aware
              that any information you disclose to these Third Party Websites
              once you access these Third Party Websites is not subject to this
              Privacy Policy. RZ does not endorse and is not responsible for the
              privacy practices of these Third Party Websites. You should review
              the privacy policy posted on the Third Party Website to understand
              how that Third Party Website collects and uses your information.
              RZ makes an effort to make it obvious to you when you leave a RZ
              Site and enter a Third Party Website, either by requiring you to
              click on a link or by notifying you on the RZ Site before you
              visit the third party site. In addition, if you see a phrase such
              as “Powered by” or “in association with” followed by the name of a
              company other than RZ, then you are on a website hosted by a
              company other than RZ.
            </p>
            <p>
              We may also include social widgets on the RZ Sites which enable
              you to interact with the associated social media services, e.g.,
              to share an article. These widgets may collect browsing data which
              may be received by the third party that provided the widgets and
              are controlled by these third parties. You may be able to manage
              your privacy preferences directly with the applicable social
              network platform.
            </p>
            <h3>
              Compliance with Law, Regulation, and Law Enforcement Requests
            </h3>
            <p>
              To cooperate with government authorities and law enforcement
              officials and private parties to enforce and comply with law, we
              may release personal information to third parties: (1) to comply
              with legal requirements such as a law, regulation, search warrant,
              subpoena or court order; (2) when we believe in good faith that
              disclosure is necessary to protect our rights, protect your safety
              or the safety of others, investigate fraud, or respond to a
              government request; or (3) in special cases, such as in response
              to a physical threat to you or others, to protect property or
              defend or assert legal rights. In addition, we may disclose
              personal information as described below.
            </p>
            <h3>How Your Information is Secured and Retained</h3>
            <p>
              We take reasonable security measures to protect the security of
              your personal information. Despite RZ’s efforts to protect your
              personal information, there is always some risk that an
              unauthorized third party may find a way around our security
              systems or that transmissions of your information over the
              Internet may be intercepted.
            </p>
            <p>
              The security of your personal information is important to us. When
              you enter personal information (including personal health
              information in various Services), we encrypt the transmission of
              that information or use SSL connections (Secure Socket Layer)
              technology.
            </p>
            <p>
              You can unsubscribe from an email newsletter by following the
              directions included at the bottom of the newsletter and using the
              “Unsubscribe” link found in the emails.
            </p>

            <p>
              All promotional emails that RZ sends with opportunities from RZ or
              with offers or informational materials from RZ on behalf of our
              sponsors will contain an opportunity to unsubscribe from getting
              additional emails of that type in the future from RZ unless you
              re-subscribe.
            </p>
            <h3>Cookies</h3>
            <p>
              Most browser software can be set to reject Cookies. Most browsers
              offer instructions on how to reset the browser to reject Cookies
              in the “Help” or “Settings” or “Preferences” section of your
              browser’s toolbar. Refer to our Cookie Policy for additional
              information about opting out of Cookies.
            </p>
            <h3>California Residents</h3>
            <p>
              If you are a California resident, California law may provide you
              with additional rights regarding our use of your personal
              information.
            </p>
            <h3>Children</h3>
            <p>
              We are committed to protecting the privacy of children. The RZ
              Sites are not designed or intended to attract children under the
              age of 13. The RZ Sites do not collect personal information from
              any person we actually know is under the age of 13.
            </p>
            <h3>Note to Users Outside of the United States</h3>
            <p>
              RZ and our technical infrastructure are located in the United
              States. The personal information that you provide to us is stored
              on servers located in the United States. If you are located in
              another jurisdiction, you should be aware that in order to provide
              the Services to you, we must transfer your personal information to
              the United States where it will be stored and processed in
              accordance with this Privacy Policy. We may transfer your
              information outside the United States to service providers with
              operations in other countries. By using the Services, you consent
              to such collection, storage and processing in the United States
              and elsewhere, though the United States and other jurisdictions
              may not afford the same level of data protection as considered
              adequate in your own country. We will take reasonable steps to
              protect your personal information. Note that your personal
              information may be available to the United States government or
              its agencies under legal process made in the United States.
            </p>
            <h3>Contacting RZ About Your Personal Information or Privacy</h3>
            <p>
              Please send us an email by using the Contact Us link at the bottom
              of every page of the RZ Sites if you have any questions about this
              Privacy Policy or the personal information we maintain about you.
              We will respond to your request within a reasonable timeframe.
            </p>
            <p>You can also contact RZ’s Privacy Office at:</p>
            <p>
              Remedy Zone <br />
              Attn: Office of Privacy
              <br />
              1942 Broadway St. STE 314C
              <br />
              Boulder, CO 80302
            </p>

            <h3>Changes to this Privacy Policy</h3>
            <p>
              We reserve the right to change or modify this Privacy Policy and
              any of our Services at any time and any changes will be effective
              upon being posted unless we advise otherwise. If we make any
              material changes to this Privacy Policy we will notify you by
              means of a notice on the RZ Sites and/or by email to our
              registered users (sent to the email address specified when you
              register) prior to the change becoming effective. By continuing to
              use the Services after changes are made to this Privacy Policy,
              you agree to such changes. We encourage you to periodically review
              this Privacy Policy for the latest information on our privacy
              practices. If you do not accept the terms of this Privacy Policy,
              we ask that you do not register with us and that you do not use
              the RZ Sites. Please exit the RZ Sites immediately if you do not
              agree to the terms of this Privacy Policy.
            </p>
          </div>
        </section>
      </main>
      <footer className="footer">
        <div className="container">
          <nav className="footer-nav">
            <a href="/terms-of-use">Terms of Use</a>
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/creative-commons">Creative Commons / DMCA</a>
          </nav>

          <p className="copyright">
            Copyright © 2023 Remedy Zones. All Rights Reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default PrivacyPolicy;
