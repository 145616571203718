import CNST from "./constants";

export const Fetch = (url, opts = {}, onSuccess, onError) => {
  // if opts.method is not provided to fetch - default is GET
  // const api = !opts.method ? CNST.CDN_API_PATH : CNST.API_PATH;
  const api = CNST.API_PATH;
  const path = `${api}${url}`;
  opts.headers = {
    ...opts.headers,
    "Content-Type": "application/json",
  };

  const parseJSON = (response) => {
    return response.text().then(function (text) {
      return text ? JSON.parse(text) : response.is204 ? { status: 204 } : {};
    });
  };

  const checkStatus = (response) => {
    if (response.status < 200 || response.status > 300) {
      const contentType = response.headers.get("Content-Type");
      if (!~contentType.indexOf("json"))
        return Promise.reject(new Error(response));

      return response
        .json()
        .then((json) => json)
        .then((json) => Promise.reject(json));
    }

    if (response.status === 204) {
      response.is204 = true;
    }

    return response;
  };

  return fetch(path, opts)
    .then(checkStatus)
    .then(parseJSON)
    .catch((error) => {
      console.log("[FETCH][ERROR]", error);
      throw error;
    });
};
